.gallery {
  height: calc(100vh - 80px);
  width: calc((100vh - 80px) * 4 / 3);
  //max-width: 75%;
  color: #fff;
  lineheight: 160px;
  textalign: center;
  background: #d4d7d5;

  .gallery-item {
    display: flex;
    align-items: center; /* align vertical */
    height: calc(100vh - 80px);
    width: calc((100vh - 80px) * 4 / 3);
    overflow: hidden;
  }

  .gallery-image {
    height: 100%;
    max-height: calc(100vh - 80px);

    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }
}

.gallery-vertical {
  height: auto;
  width: 100%;
  color: #fff;
  textalign: center;
  background: #c8d7cd;

  .gallery-item {
    display: flex;
    align-items: center; /* align vertical */
    //height: 100%;
    width: 100%;
    //max-height: 300px;
    height: auto;
    overflow: auto;
  }

  .gallery-image {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    //max-height: 300px;
  }
}
@primary-color: #527F51;