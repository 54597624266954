.divider {
  align-items: center; /* align vertical */
  margin-top: 50px;
  margin-bottom: 30px;
  //border-top: 0.5px solid;
  //border-bottom: 0.5px solid;

  .ant-col {
    min-width: 10px;
  }

  h1 {
    margin: 0;
  }

  img {
    width: 100%;
    max-width: 200px;
  }
}

.divider-text {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  height: 100%;
}

@primary-color: #527F51;