.service-provider-card {
  height: 100%;

  .ant-card-cover {
    display: inline-flex;
    height: 80%;
  }

  .service-provider-image {
    display: inline-flex;
    margin-top: auto;
    margin-bottom: auto;
  }
}
@primary-color: #527F51;