.logo {
  float: left;
  height: 100%;
  width: 110px;
  margin: 0px 10px 0px 0;
  display: flex;

  img {
    height: 100%;
    width: auto;
    overflow: hidden;
    vertical-align: baseline !important;
  }
}

.site-layout {
  height: 100%;
  background: #fff;
  margin-top: 80px;
}

.site-layout-background {
  height: 100%;
  background: #fff;
  margin-top: 80px;
}

.header {
  position: fixed;
  z-index: 1 !important;
  width: 100%;
  height: 80px;
  background: white;

  .ant-menu-horizontal {
    border-bottom: 0;
  }
}

.gallery-container {
  display: flex;
  margin: 0;

  .ant-col {
    margin: 0 !important;

    .ant-row {
      margin: 0 !important;
    }
  }

  .gallery-container-col {
    padding-right: 0 !important;
    padding-left: 0 !important;
    width: calc((100vh - 80px) * 4 / 3);
  }
  .welcome-container-col {
    padding-right: 5px !important;
    padding-left: 5px !important;
    width: calc(100vw - ((100vh - 80px) * 4 / 3));
    min-width: 400px;
  }
}


#welcome {
  border-top: 0 !important;
}

@media screen and (max-width: 1000px) {
  .header {
    padding-right: 20px;
    padding-left: 20px;
    height: 50px;
  }

  .logo {
    height: 50px;
    width: 60px;
  }

  .site-layout {
    margin-top: 50px;
  }

  .site-layout-background {
    margin-top: 50px;
  }

  .gallery-container {
    display: flex;
    margin: 0;

    .ant-col {
      margin: 0 !important;

      .ant-row {
        margin: 0 !important;
      }
    }

    .gallery-container-col {
      padding-right: 0 !important;
      padding-left: 0 !important;
      width: calc((100vh - 50px) * 4 / 3);
    }
    .welcome-container-col {
      padding-right: 5px !important;
      padding-left: 5px !important;
      width: calc(100vw - ((100vh - 50px) * 4 / 3));
      min-width: 400px;
    }
  }
}

@primary-color: #527F51;