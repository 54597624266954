.sign-in-item {
  cursor: default;
}

.language-flag {
  cursor: default;
}

.menu {
  float: right;
  height: 100%;
  font-family: 'Cookie', cursive !important;
  line-height: 80px !important;
}

.menu li:hover {
  color: #cfbe76;
}

.submenu {
  font-family: 'Cookie', cursive !important;
}

@media screen and (min-width: 1300px) {
  .menu {
    font-size: x-large;
  }

  .sign-in-item {
    font-size: x-large;
    width: 150px;
  }

  .language-flag {
    width: 100px;
  }
}

@media screen and (max-width: 1300px) and (min-width: 1000px) {
  .menu {
    font-size: large;
  }

  .menu-item {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .sign-in-item {
    font-size: large;
    width: 150px;
  }

  .language-flag {
    width: 50px;
  }
}

@media screen and (max-width: 1000px) and (min-width: 750px) {
  .menu {
    font-size: medium;
    line-height: 50px !important;
  }

  .menu-item {
    padding-left: 7px !important;
    padding-right: 7px !important;
  }

  .sign-in-item {
    font-size: medium;
    width: 100px;
    padding-left: 7px !important;
    padding-right: 7px !important;
  }

  .language-flag {
    width: 40px;
    padding-left: 7px !important;
    padding-right: 7px !important;
  }
}

@media screen and (max-width: 750px) {
  .menu {
    font-size: large;
    line-height: 50px !important;
  }

  .menu-item {
    padding-left: 7px !important;
    padding-right: 7px !important;
  }

  .sign-in-item {
    font-size: medium;
    width: 90px;
    padding-left: 7px !important;
    padding-right: 7px !important;
  }

  .language-flag {
    width: 40px;
    padding-left: 7px !important;
    padding-right: 7px !important;
  }
}

@primary-color: #527F51;