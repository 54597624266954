.welcome {
  font-size: large;
  height: 100%;
  margin-bottom: 30px;

  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .warning {
    color: red;
  }
}

.sign-in-card-row {
  margin-top: 80px;
  width: 100%;
}

@primary-color: #527F51;