@font-face {
  font-family: 'Arima Madurai';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/arimamadurai/v15/t5t4IRoeKYORG0WNMgnC3seB1V3Pqg.ttf) format('truetype');
}
@font-face {
  font-family: 'Arima Madurai';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/arimamadurai/v15/t5t7IRoeKYORG0WNMgnC3seB1fHuips.ttf) format('truetype');
}
@font-face {
  font-family: 'Arima Madurai';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/arimamadurai/v15/t5t7IRoeKYORG0WNMgnC3seB1ZXtips.ttf) format('truetype');
}
@font-face {
  font-family: 'Arima Madurai';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/arimamadurai/v15/t5tmIRoeKYORG0WNMgnC3seB7Tk.ttf) format('truetype');
}
@font-face {
  font-family: 'Arima Madurai';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/arimamadurai/v15/t5t7IRoeKYORG0WNMgnC3seB1c3sips.ttf) format('truetype');
}
@font-face {
  font-family: 'Arima Madurai';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/arimamadurai/v15/t5t7IRoeKYORG0WNMgnC3seB1YXqips.ttf) format('truetype');
}
@font-face {
  font-family: 'Arima Madurai';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/arimamadurai/v15/t5t7IRoeKYORG0WNMgnC3seB1Znpips.ttf) format('truetype');
}
@font-face {
  font-family: 'Arima Madurai';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/arimamadurai/v15/t5t7IRoeKYORG0WNMgnC3seB1b3oips.ttf) format('truetype');
}
