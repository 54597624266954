.sign-in-form {
  display: flex !important;
  align-items: center;
  align-content: center;
  justify-content: center;
  vertical-align: middle;
}

.sign-in-form .ant-form-item-label {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  vertical-align: middle !important;
}

.sign-in-form * {
  min-width: 50px;
}

.sign-in-form-item {
  width: 100%;
}

@media screen and (max-width: 600px) {
  .ant-card-body {
    padding: 12px;

   .ant-form-item-label {
      margin: 0;
    }

    .ant-form-item-control {
      margin: 0;
    }
  }

  .sign-in-form {
    margin: 0;
  }

  .sign-in-form * {
    min-width: 30px;
  }
}
@primary-color: #527F51;