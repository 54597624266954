@import '~antd/dist/antd.less';
@import 'variables';
@import url('https://fonts.googleapis.com/css2?family=Cookie&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arima+Madurai:wght@100;200;300;400;500;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'Cookie', cursive;
  font-size: large;
}

@font-face {
  font-family: 'Arima Madurai', cursive;
  font-style: normal;
}

@font-face {
  font-family: 'Oswald', sans-serif;
  font-style: normal;
}

@font-face {
  font-family: 'Playfair Display', serif;
  font-style: normal;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Playfair Display', serif;
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

code {
}

h1 {
  font-family: 'Cookie', cursive !important;
  font-size: xx-large;
}

h2 {
  font-family: 'Cookie', cursive !important;
  font-size: x-large;
}

h3 {
  font-family: 'Playfair Display', serif;
  font-size: large;
  font-weight: bold;
}

h4 {
  font-family: 'Playfair Display', serif;
  font-size: medium;
  font-weight: 500;
}

button {
  font-size: large;
  background: @main-color;
  border-radius: 2px;
  color: white;
}

.ant-col {
  min-width: 300px;
  margin-top: 1em;
  margin-bottom: 1em;
}

@media screen and (max-width: 750px) {
  h1 {
    font-size: x-large;
  }

  h2, h3 {
    font-size: large;
  }

  h4 {
    font-size: medium;
  }
}

@media screen and (max-width: 1000px) and (min-width: 500px) {
  p, span {
    font-size: medium !important;
  }
}

@media screen and (max-width: 500px) and (min-width: 0){
  p, span {
    font-size: small !important;
  }
}

@primary-color: #527F51;