.spotify {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 11px;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
@primary-color: #527F51;