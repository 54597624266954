.welcome-see-once-again {
  height: 50%;
  -webkit-animation: fadeinout 3s linear forwards;
  animation: fadeinout 3s linear forwards;
}

@-webkit-keyframes fadeinout {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeinout {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.welcome-see-once-again-arrow {
  font-size: 70px;
}
@primary-color: #527F51;