.movie-card {
  min-height: 100px;
  margin: 10px;
}

.movie-iframe {
  aspect-ratio: 16/9;
  min-height: 100px;
  width: 100%;
}
@primary-color: #527F51;