.flag {
  height: 30px;
  border: 1px solid #58595b;
  vertical-align: middle;
  cursor: pointer;
}

.flag-button {
  padding: 0;
  width: 100%;
  height: auto;
  border: 0;
}

@primary-color: #527F51;